import React from "react"
import DonateButton from "../components/buttons/donateButton"
import HomeButton from "../components/buttons/homeButton"

export default function CancelledDonation() {
  return (
    <>
      <div className="w=full flex flex-col flex-wrap desktop:justify-center phone:pt-24 items-center bg-blue-800 min-h-screen">
        <div
          className="phone:w-4/5 text-center bg-white rounded-md border
         border-blue-500 flex justify-center items-center flex-col shadow-lg shadow-gray-800
         phone:px-8 p-12"
        >
          <h1 className="phone:text-4xl text-6xl text-stone-700 pb-8">
            Donation Cancelled
          </h1>
          <div className="phone:w-4/5 w-full">
            <p className="text-stone-600 pb-8">
              If you would like to try again, please click the donate button.
            </p>
          </div>
          <div className="flex flex-row gap-6">
            <DonateButton>Donate</DonateButton>
            <HomeButton>Home</HomeButton>
          </div>
        </div>
      </div>
    </>
  )
}
