import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouseUser } from "@fortawesome/free-solid-svg-icons"

export default function HomeButton({ children }) {
  return (
    <Link to="/">
      <button className="bg-gradient-to-b from-blue-500 to-blue-800 font-oswald text-lg tablet:text-xl py-2 px-6 rounded border-2 border-blue-800 shadow-md shadow-gray-900/80">
        <span className="pr-1">
          <FontAwesomeIcon icon={faHouseUser} color="white" />
        </span>
        <span className="text-white font-extralight">{children}</span>
      </button>
    </Link>
  )
}

HomeButton.propTypes = {
  children: PropTypes.node.isRequired,
}
